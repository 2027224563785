'use strict';
/**
 *
 * ctor(details)    details contains:
 *      {
 *          options:    see below for details
 *          title:      optional - shown above the list of options
 *          origin:     the element that this context menu was opened from. used for HD to reposition the ctxt menu content
 *      }
 *
 * options an array of individual options to pick from.
 *      {
 *          title           text shown for this option
 *          [action]        optional action to call when the option was selected.
 *          [titleColor]    optional titleColor
 *          [loadFn]        optional the loadFn will be called and the result shown.
 *          [disabled]      optional, if true, the cell is deactivated, but shown and not clickable
 *      }
 *
 * onOptionSelected(idx)        called when an option is selected that has no action
 */

window.GUI = function (GUI) {
    class ContextMenu extends GUI.LxContextMenuBase {
        constructor(details) {
            super(...arguments);
            Debug.GUI.ContextMenu && console.log(this.name, "constructor");
        }

        getContentView() {
            return this._loadOptions().then(function () {
                var content = this.getTableContent(),
                    tableView;
                this.tableView2DataSource = tableViewDataSource(content, null, this);
                this.tableView2Delegate = tableViewDelegate(content);
                tableView = new GUI.TableViewV2(this.tableView2DataSource, this.tableView2Delegate);
                GUI.animationHandler.addCssClass(tableView.getElement(), "placeholder__content");
                return tableView.reload().then(function () {
                    return tableView;
                });
            }.bind(this));
        }

        getTableContent() {
            return [{
                rows: this._prepareContentRows()
            }];
        }

        styleForTableView() {
            return TableViewStyle.PLAIN;
        }

        /**
         * Iterates over options, if required, loads the option content async.
         * @returns {*} Promise that resolves once all options are loaded. Also resolves when errors occur.
         * @private
         */
        _loadOptions() {
            Debug.GUI.ContextMenu && console.log(this.name, "_loadOptions");
            var promises = [true];
            this.options.forEach(function (option) {
                try {
                    option.loadFn && promises.push(option.loadFn().then(function (res) {
                        Debug.GUI.ContextMenu && console.log(this.name, "   async option loaded: ", res, option); // if reloading is required when opening up again, it needs to be passed back in "res"

                        option.loadFn = null; // assign res attributes onto option

                        Object.assign(option, res);
                    }.bind(this), function () {
                        // Just resolve if there was an error, we won't risc an empty context menu just because one loadFn was rejected
                        console.warn("Couldn't load async context entry");
                        return Q.resolve();
                    }));
                } catch (ex) {
                    console.error(this.name, "_loadOptions: could not load an option! Exception: ", ex);
                }
            }.bind(this)); // show a waiting popup if it takes too long

            return NavigationComp.showWaitingFor(Q.all(promises), _("loading.data"), null, // no message
                true // show cancel button
            ).fail(function (err) {
                console.error(this.name, "Could not load all options: ", err);
            }.bind(this));
        }

        /**
         * Called when an option was tapped. It will either perform the option action provided or call the corresponding
         * delegate method.
         * @param idx       the idx of the selected option. used for informing the delegate
         * @param [action]  an optional action to take. if provided, the delegate will not be informed.
         * @private
         */
        _handleOptionTapped(idx, action) {
            var promise;

            if (action) {
                promise = Q.when(action());
            } else if (this.onOptionSelected) {
                promise = Q.when(this.onOptionSelected(idx));
            } else {
                promise = Q.when(true);
            }

            promise.then(function () {
                this._handleCancel(); // close the view

            }.bind(this));
        }

        _prepareContentRows() {
            var rows = [],
                row;
            this.options.forEach(function (option) {
                row = this._prepareOptionRow(option, rows.length);
                row && rows.push(row);
            }.bind(this));
            return rows;
        }

        _prepareOptionRow(option, idx) {
            var row, action;

            if (!option.title || option.title === "") {
                Debug.GUI.ContextMenu && console.log(this.name, "_prepareOptionRow: no title, skip: " + idx, option);
                return null; // skip row.
            }

            action = this._handleOptionTapped.bind(this, idx, option.action);
            row = {
                content: {
                    title: option.title,
                    disclosureText: option.disclosureText,
                    clickable: true,
                    titleColor: option.titleColor,
                    disabled: !!option.disabled
                },
                action: action
            };

            if (option.hasOwnProperty("selected")) {
                row.type = GUI.TableViewV2.CellType.CHECKABLE;
                row.content.selected = option.selected;
                row.didCheckCell = action;
                row.content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED;
            }

            if (option.hasOwnProperty("value")) {
                row.type = GUI.TableViewV2.CellType.SLIDER;
                row.content.value = option.value;
                row.content.step = option.step;
                row.content.minValue = option.minValue;
                row.content.maxValue = option.maxValue;
                row.sliderClicked = option.valueChanged;
                row.sliderDragged = option.valueChanged;
            }

            if (!!option.separator) {
                row.type = GUI.TableViewV2.CellType.TEXT;
                row.content.text = option.title;
                row.content.class = "separator-cell";
                delete row.action;
            }

            return row;
        }

    }

    GUI.LxContextMenu = ContextMenu;
    return GUI;
}(window.GUI || {});
