'use strict';

class LxPresenceSimulationBar extends GUI.View {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate() {
            return $('<div class="lx-presence-simulation-bar">' + ImageBox.getResourceImageWithClasses("resources/Images/General/icon-presence-simulation.svg", "simulation-bar__icon") + '<div class="simulation-bar__title text-overflow-ellipsis">' + _('presence-simulation') + "&nbsp;" + '</div>' + '<div class="simulation-bar__subtitle"></div>' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor() {
        super(LxPresenceSimulationBar.Template.getTemplate());
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.subtitleLabel = this.element.find('.simulation-bar__subtitle');
    }

    // Public methods
    setPresenceTime(from, to) {
        this.subtitleLabel.html("(" + from + "&nbsp;-&nbsp;" + to + "&nbsp;" + _('timeSuffix') + ")");
    }

}

GUI.LxPresenceSimulationBar = LxPresenceSimulationBar;
