'use strict';

class lxControlBar extends GUI.View {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate() {
            return $('<div class="lx-control-bar">' + '<div clas="control-bar__icon-placeholder"></div>' + '<div class="control-bar__title text-overflow-ellipsis"></div>' + '<div class="control-bar__subtitle"></div>' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(icon, title, subTitle) {
        super(lxControlBar.Template.getTemplate());
        this.icon = icon;
        this.title = title;
        this.subTitle = subTitle;
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.iconView = this.element.find('.control-bar__icon-placeholder');
        this.titleLabel = this.element.find('.control-bar__title');
        this.subtitleLabel = this.element.find('.control-bar__subtitle');

        if (this.icon) {
            this.setIcon(this.icon);
        }

        if (this.title) {
            this.setTitle(this.title);
        }

        if (this.subTitle) {
            this.setSubTitle(this.subTitle);
        }
    }

    setIcon(iconSrc) {
        var icon = ImageBox.getResourceImageWithClasses(iconSrc, "control-bar__icon");
        this.iconView.html(icon);
    }

    setTitle(title) {
        this.titleLabel.html(title);
    }

    setSubTitle(subTitle) {
        this.subtitleLabel.html(subTitle);
    }

}

GUI.LxControlBar = lxControlBar;
