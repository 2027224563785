window.GUI = function (GUI) {
    class ContextMenuV2 extends GUI.LxContextMenu {
        constructor(details) {
            super(...arguments);
            this.delegate = details.delegate;
        }

        beforeAnimateIn() {
            return this._repositionContent();
        }

        _animateIn() {
            var elmAnimateDef = Q.defer(),
                contentPlaceholderDef = Q.defer();

            this._animate(this.element, {
                opacity: [1, 0]
            }, function () {
                elmAnimateDef.resolve();
            });

            this._animate(this.elements.contentPlaceholder, {
                scale: [1, 0]
            }, function () {
                contentPlaceholderDef.resolve();
            });

            return Q.all([elmAnimateDef.promise, contentPlaceholderDef.promise]);
        }

        _animateOut() {
            this.origin.classList.remove("over-darkener");
            this.delegate.onContextMenuHide && this.delegate.onContextMenuHide();
            var def = Q.defer();

            this._animate(this.element, {
                opacity: [0, 1]
            });

            this._animate(this.elements.contentPlaceholder, {
                scale: [0, 1]
            }, function () {
                def.resolve();
            });

            return def.promise;
        }

        /**
         * Called when on HD and the hammer event that did cause the contextMenu to appear are known. In this case
         * the content shown in the context menu should be presented next to that location.
         * @private
         */
        _repositionContent() {
            var contextRect = this.elements.contentPlaceholder[0].getBoundingClientRect(),
                extracted = this.origin.getBoundingClientRect(),
                origXPos = extracted.left + contextRect.width,
                origYPos = extracted.bottom + 5,
                offsetRight = window.innerWidth - origXPos,
                rightToLeft = false;

            this._setAnimationType(this.ANIMATION_TYPE.RIGHT);

            if (origXPos > window.innerWidth) {
                origXPos = extracted.right;
                offsetRight = window.innerWidth - origXPos;

                this._setAnimationType(this.ANIMATION_TYPE.LEFT);

                rightToLeft = true;
            }

            var contextMenuHeight = this.options.length * 64 + 1;

            if (origYPos + 5 + contextMenuHeight > window.innerHeight) {
                origYPos = extracted.top - 5 - contextMenuHeight;

                if (rightToLeft) {
                    this._setAnimationType(this.ANIMATION_TYPE.BOTTOM_RIGHT);
                } else {
                    this._setAnimationType(this.ANIMATION_TYPE.BOTTOM_LEFT);
                }
            }

            return GUI.animationHandler.schedule(function () {
                this.elements.contentPlaceholder.css("margin-bottom", "auto");
                this.elements.contentPlaceholder.css("margin-left", "auto");
                this.elements.contentPlaceholder.css("margin-right", offsetRight);
                this.elements.contentPlaceholder.css("margin-top", origYPos);
            }.bind(this));
        }

    }

    GUI.LxContextMenuV2 = ContextMenuV2;
    return GUI;
}(window.GUI || {});
