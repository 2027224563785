'use strict';

/**
 * Contains a spinning indicator, a title and a message - all of it centered and aligned nicely.
 * By default it has a dark background.
 */

class LxWaitingView extends GUI.View {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(dark) {
            var spinner = dark ? "css-spinner-b" : "css-spinner-a";
            var ownClass = dark ? "lx-waiting-view--dark" : "";
            return $('<div class="lx-waiting-view ' + ownClass + '">' + '   <div class="lx-waiting-view__spinner ' + spinner + '"></div>' + '   <div class="lx-waiting-view__title" />' + '   <div class="lx-waiting-view__message" />' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    /**
     *
     * @param title
     * @param message
     * @param [dark=false]  if true, it will always be dark
     */
    constructor(title, message, dark) {
        super(LxWaitingView.Template.getTemplate(dark));
        this.title = !title ? "" : title;
        this.message = !message ? "" : message;
    }

    viewDidLoad() {
        var promise = super.viewDidLoad(...arguments);
        this.elements = {
            waitingTitle: this.element.find('.lx-waiting-view__title'),
            waitingMessage: this.element.find('.lx-waiting-view__message')
        };
        this.setTitle(this.title);
        this.setMessage(this.message);
        return promise;
    }

    setTitle(title) {
        this.title = title;
        GUI.animationHandler.setHtml(this.elements.waitingTitle, title);
    }

    setMessage(message) {
        this.message = message;
        GUI.animationHandler.setHtml(this.elements.waitingMessage, message);
    }

    setTranslucent() {
        this.element.addClass("lx-waiting-view--translucent");
    }

}

GUI.LxWaitingView = LxWaitingView;
