'use strict';
/**
 *
 * ctor(details)    details contains:
 *      {
 *          options:    see below for details
 *          title:      optional - shown above the list of options
 *          origin:     the element that this context menu was opened from. used for HD to reposition the ctxt menu content
 *      }
 *
 *  Slider Specific --> see lxSliderView for details
 *      color       used as active color for slider
 *      minValue    minimum value of slider
 *      maxValue    maximum value of slider
 *      step        step width for slider
 *      value       the initial value of the slider
 *      onSliderChanged         the function to call when the slider changes.
 *      onSliderDisappear       the function to call when the slider disappears.
 */

window.GUI = function (GUI) {
    class LxSliderContextMenu extends GUI.LxContextMenuBase {
        constructor(details) {
            super(...arguments);
            Debug.GUI.ContextMenu && console.log(this.name, "constructor");
        }

        getContentView() {
            return new GUI.LxSliderView(this.options);
        }

        showConfirmButton() {
            return true;
        }

    }

    GUI.LxSliderContextMenu = LxSliderContextMenu;
    return GUI;
}(window.GUI || {});
