'use strict';
/**
 *
 * ctor(details)    details contains:
 *      color       used as active color for slider
 *      minValue    minimum value of slider
 *      maxValue    maximum value of slider
 *      step        step width for slider
 *      value       the initial value of the slider
 *      onSliderChanged         the function to call when the slider changes.
 *      onSliderDisappear       the function to call when the slider disappears (with the final value)
 *      format      the format to show the value with
 */

window.GUI = function (GUI) {
    class LxSliderView extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getTemplate() {
                return '' + '<div class="lx-slider-view">' + '<div class="lx-slider-view__slider"></div>' + '<div class="lx-slider-view__button lx-slider-view__button-minus">' + ImageBox.getResourceImageWithClasses(Icon.MINUS, "button__icon") + '</div>' + '<div class="lx-slider-view__button lx-slider-view__button-plus">' + ImageBox.getResourceImageWithClasses(Icon.PLUS, "button__icon") + '</div>' + '<div class="lx-slider-view__value"></div>' + '</div>';
            } //endregion Static


        }; //endregion Static

        constructor(details) {
            super($(LxSliderView.Template.getTemplate()));
            this.options = details;
            this.options.format = details.format || "%.1f";
            this.options.minValue = details.hasOwnProperty("minValue") ? details.minValue : 0;
            this.options.maxValue = details.hasOwnProperty("maxValue") ? details.maxValue : 100;
            this.options.value = details.value || this.options.minValue;
            this.options.step = details.step || 0.5;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() =>{
                this.elements = {};
                this.elements.sliderCntr = this.getElement().find(".lx-slider-view__slider");
                this.elements.value = this.getElement().find(".lx-slider-view__value");
                this.elements.plus = this.getElement().find(".lx-slider-view__button-plus");
                this.elements.minus = this.getElement().find(".lx-slider-view__button-minus");

                return this.appendReactComp({
                    reactComp: GUI.LxSlider,
                    compProps: {
                        activeColor: this.options.color ? this.options.color : window.Styles.colors.stateActive,
                        antiGhostTimer: true,
                        min: this.options.minValue,
                        max: this.options.maxValue,
                        step: this.options.step,
                        value: this.options.value
                    },
                    target: this.elements.sliderCntr
                }).then((instance) => {
                    this.slider = instance;

                    this._updateValueLabel(this.options.value);

                    this.slider.onPositionChanged = this._onSliderChanged.bind(this);
                    this.buttons = {};
                    this.buttons.plus = new GUI.LxButton(this, this.elements.plus);
                    this.buttons.minus = new GUI.LxButton(this, this.elements.minus);
                    this.addToHandledSubviews(this.buttons.plus);
                    this.addToHandledSubviews(this.buttons.minus);
                    this.buttons.plus.activateTicks();
                    this.buttons.minus.activateTicks();
                    this.buttons.plus.onButtonTapped = this._handleButtonTapped.bind(this, true, false);
                    this.buttons.plus.onButtonTicked = this._handleButtonTapped.bind(this, true, true);
                    this.buttons.minus.onButtonTapped = this._handleButtonTapped.bind(this, false, false);
                    this.buttons.minus.onButtonTicked = this._handleButtonTapped.bind(this, false, true);
                })
            });
        }

        viewWillDisappear() {
            this.options.onSliderDisappear && this.options.onSliderDisappear(this.options.value);
            return super.viewWillDisappear(...arguments);
        }

        _onSliderChanged(value, isOff, isDragging) {
            this._updateValueLabel(value);

            this.options.value = value;
            this.options.onSliderChanged && this.options.onSliderChanged(value, isOff, isDragging);
        }

        _updateValueLabel(value) {
            this.elements.value.text(lxFormat(this.options.format, value));
        }

        _handleButtonTapped(increase, ticking) {
            if (increase) {
                this.options.value += this.options.step;
            } else {
                this.options.value -= this.options.step;
            }

            this.options.value = Math.min(this.options.value, this.options.maxValue);
            this.options.value = Math.max(this.options.value, this.options.minValue);
            this.slider.setPosition(this.options.value);

            this._updateValueLabel(this.options.value);

            this.options.onSliderChanged && this.options.onSliderChanged(this.options.value, false, ticking);
        }

    }

    GUI.LxSliderView = LxSliderView;
    return GUI;
}(window.GUI || {});
