'use strict';

window.GUI = function (GUI) {
    class LxVerticalSliderButtonView extends GUI.View {
        //region Static
        static Template = function () {
            var getButtonContainer = function getButtonContainer() {
                return $('<div class="content__button-container">' + getStepButton(true) + getStepButton(false) + '</div>');
            };

            var getStepButton = function getStepButton(isInc) {
                var imgSrc = isInc ? 'resources/Images/General/button-plus.svg' : 'resources/Images/General/button-minus.svg';
                return '<div class="container__btn-touch-area">' + ImageBox.getResourceImageWithClasses(imgSrc, "btn-touch-area__btn clickable") + '</div>';
            };

            return {
                getButtonContainer: getButtonContainer
            };
        }(); //endregion Static

        constructor(config) {
            super($('<div />'));
            this.config = config;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                var sliderContainer = $("<div class='content__slider-container'></div>");
                this.element.append(sliderContainer);

                let hasCustomRate = this.config.hasOwnProperty("updateRate");



                var buttonContainer = LxVerticalSliderButtonView.Template.getButtonContainer();
                GUI.animationHandler.append(buttonContainer, sliderContainer);
                var buttons = buttonContainer.children();
                this.btnInc = new GUI.LxButton(this, buttons[0], Color.BUTTON_GLOW, null, true, 0);
                this.btnDec = new GUI.LxButton(this, buttons[1], Color.BUTTON_GLOW, null, true, 0);
                this.btnInc.useChildsAsActiveParts("fill");
                this.btnDec.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.btnInc);
                this.addToHandledSubviews(this.btnDec);
                this.btnInc.onButtonHit = this._increaseValue.bind(this);
                this.btnInc.onButtonTicked = this._increaseValue.bind(this);
                this.btnDec.onButtonHit = this._decreaseValue.bind(this);
                this.btnDec.onButtonTicked = this._decreaseValue.bind(this);
                return this.appendReactComp({
                    reactComp: GUI.LxVerticalSlider,
                    compProps: {
                        config: this.config,
                        hasCustomRate: hasCustomRate,
                        containerStyle: {
                            //10 => globalStyles.spacings.gaps.small
                            marginLeft: 50 + 10
                        }
                    },
                    target: sliderContainer
                }).then(instance => {
                    this.slider = instance
                    this.slider.onPositionChanged = this._positionChanged.bind(this);
                    this.slider.onDraggingChanged = this._positionChanging.bind(this);
                    this.pos && this.slider.setPosition(this.pos);
                });
            });
        }

        updateProperties(props) {
            this.config = props;
            this.slider && this.slider.updateProperties(props);
        }

        setPosition(pos) {
            this.pos = parseFloat(pos);
            this.slider && this.slider.setPosition(this.pos);
        }

        // Private

        /**
         * Dispatches the onPositionChanged callback
         * @param pos
         * @private
         */
        _positionChanged(pos) {
            this.onPositionChanged && this.onPositionChanged.call(this, pos);
        }

        /**
         * Will dispatch the onPositionChanging callback (meaning the value change is ongoing)
         * @param pos
         * @private
         */
        _positionChanging(pos) {
            if (pos !== this.pos) {
                this.onPositionChanging && this.onPositionChanging.call(this, pos);
            }
        }

        /**
         * Decrease the value and checks if the current value is already at its minimum
         * @private
         */
        _decreaseValue() {
            if (this.pos <= this.config.min) {
                // nothing to do if the pos is already at the minimum
                return;
            }

            var newVal = this.pos - this.config.step;

            if (newVal < this.config.min) {
                newVal = this.config.min;
            }

            this.slider.setPosition(newVal);

            this._positionChanged(newVal);

            this.slider.resetAntiGhost();
        }

        /**
         * Increases the value and checks if the current value is already at its maximum
         * @private
         */
        _increaseValue() {
            if (this.pos >= this.config.max) {
                // nothing to do if the pos is already at the maximum
                return;
            } // Ensures to set valid positions
            // We can't set 0.5 if the min value is 10


            this.pos = Math.max(this.pos, this.config.min);
            var newVal = this.pos + this.config.step;

            if (newVal > this.config.max) {
                newVal = this.config.max;
            }

            this.slider.setPosition(newVal);

            this._positionChanged(newVal);

            this.slider.resetAntiGhost();
        }

    }

    /**
     * A view with an Vertical Slider and +/- buttons on the right side
     */
    GUI.LxVerticalSliderButtonView = LxVerticalSliderButtonView;
    return GUI;
}(window.GUI || {});
