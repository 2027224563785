/**
 * Created by woessto on 28.06.17.
 */
'use strict';
/*
 checked
 title

 // methods
 setChecked(checked)

 // callbacks
 onCheckboxChanged(value, src)

 */

window.GUI = function (GUI) {
    class LxCheckbox extends GUI.View {
        //region Static
        static Template = function () {
            var getTickboxContent = function getTickboxContent(content) {
                var titleElem = '';

                if (content.title) {
                    titleElem = '<div class="lx-checkbox__title">' + content.title + '</div>';
                }

                return $('<div class="lx-checkbox__tickbox">' + '<div class="tickbox__box">' + ImageBox.getResourceImageWithClasses(Icon.TitleBar.TICK, "box__tick-icon") + '</div>' + '</div>' + titleElem);
            };

            return {
                getTickboxContent: getTickboxContent
            };
        }(); //endregion Static

        constructor(configuration) {
            super($("<div />"));
            this.config = configuration;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.element.append(LxCheckbox.Template.getTickboxContent(this.config));
            this.elements = {
                tick: this.element.find(".box__tick-icon")
            };
            this.tickBtn = new GUI.LxButton(this, this.element[0]);
            this.addToHandledSubviews(this.tickBtn);
            this.setChecked(this.config.checked);
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.tickBtn.onButtonTapped = this._toggleChecked.bind(this);
        }

        viewDidDisappear() {
            this.tickBtn.onButtonTapped = null;
            super.viewDidDisappear(...arguments);
        }

        // public methods
        setChecked(checked) {
            this.isChecked = checked;

            if (this.isChecked) {
                this.elements.tick.css("display", "");
            } else {
                this.elements.tick.hide();
            }
        }

        // Private methods
        _toggleChecked() {
            this.setChecked(!this.isChecked);

            if (this.onCheckboxChanged) {
                this.onCheckboxChanged(this.isChecked, this);
            }
        }

    }

    GUI.LxCheckbox = LxCheckbox;
    return GUI;
}(window.GUI || {});
