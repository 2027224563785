'use strict';
/**
 * ----------------------------------------------------------------
 * |                                                              |
 * |  (-)                   formatedValue                     (+) |
 * |                                                              |
 * ----------------------------------------------------------------
 *
 * ctor(details)    details contains:
 *      [value]             the value to show initially
 *      [valueFormat]       the format to show the value with
 *      [stepWidth]         the step width for inc+decreasing
 *      [minValue]          the minimum possible value
 *      [maxValue]          the maximum possible value
 *      [finishedDelay]     milliseconds how long to delay the valueChangedFn with finished set to true after the button was
 *                          released. If missing, finished will be set asap when the button has been released
 *      [valueChangedFn]    the fn to call when the value has changed (with the value as first and onGoing as second arg)
 *                              onGoing --> boolean, will be false when the button has been released for "finishedDelay" ms
 *                                          remains "true" as long as the button is pressed.
 *
 */

window.GUI = function (GUI) {
    class LxValueSelectionView extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getTemplate() {
                return '' + '<div class="lx-value-selection-view">' + '<div class="lx-value-selection-view__left lx-value-selection-view__side">' + ImageBox.getResourceImageWithClasses(Icon.MINUS, "side__icon") + '</div>' + '<div class="lx-value-selection-view__center">' + '<div class="center__text"></div>' + '</div>' + '<div class="lx-value-selection-view__right lx-value-selection-view__side">' + ImageBox.getResourceImageWithClasses(Icon.PLUS, "side__icon") + '</div>' + '</div>';
            } //endregion Static


        }; //endregion Static

        constructor(details) {
            super($(LxValueSelectionView.Template.getTemplate()));
            this.details = details;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.elements = {
                    value: this.element.find(".center__text"),
                    plus: this.element.find(".lx-value-selection-view__right"),
                    minus: this.element.find(".lx-value-selection-view__left")
                };
                this.buttons = {
                    increase: new GUI.LxButton(this, this.elements.plus[0]),
                    decrease: new GUI.LxButton(this, this.elements.minus[0])
                };
                this.addToHandledSubviews(this.buttons.increase);
                this.addToHandledSubviews(this.buttons.decrease);

                this._registerButtonCallbacks(this.buttons.increase, this.details.stepWidth);

                this._registerButtonCallbacks(this.buttons.decrease, this.details.stepWidth * -1);

                this.buttons.increase.activateTicks();
                this.buttons.decrease.activateTicks(); // call setDetails to ensure everything is up to date on the UI.

                this.setDetails(this.details);
            }.bind(this));
        }

        setDetails(details) {
            this.details = details;
            this.details.stepWidth = this.details.stepWidth || 1;
            this.details.minValue = this.details.minValue || 0;
            this.details.maxValue = this.details.maxValue || 100;
            this.details.valueFormat = this.details.valueFormat || "%.0f";
            this.details.value = this.details.value || 0;
            this.details.finishedDelay = this.details.finishedDelay || 0;

            this._updateInternalValue(this.details.value);
        }

        setValue(value) {
            this._updateInternalValue(value);
        }

        _registerButtonCallbacks(button, step) {
            button.onButtonTapped = this._handleChangeValue.bind(this, step, false);
            button.onButtonTicked = this._handleChangeValue.bind(this, step, true);
        }

        _sanitizeValue(value) {
            value = Math.min(value, this.details.maxValue);
            value = Math.max(value, this.details.minValue);
            return value;
        }

        _updateInternalValue(value) {
            this.details.value = Math.min(value, this.details.maxValue);
            this.details.value = Math.max(value, this.details.minValue);
            this.elements.value.text(lxFormat(this.details.valueFormat, this.details.value));
        }

        _handleChangeValue(modification, onGoing) {
            this._updateInternalValue(this.details.value + modification);

            this._stopFinishedTimeout();

            if (!onGoing && this._startFinishedTimeout()) {
                // report as if it's still ongoing, as the ongoing 'false' will be dispatched by the timer.
                this._dispatchValueChange(this.details.value, true);
            } else {
                this._dispatchValueChange(this.details.value, onGoing);
            }
        }

        _dispatchValueChange(value, onGoing) {
            this.details.valueChangedFn && this.details.valueChangedFn(value, onGoing);
        }

        _startFinishedTimeout() {
            var timeoutStarted = false;

            if (this.details.finishedDelay) {
                timeoutStarted = true;
                this._finishedTimeout = setTimeout(this._dispatchValueChange.bind(this, this.details.value, false), this.details.finishedDelay);
            }

            return timeoutStarted;
        }

        _stopFinishedTimeout() {
            this._finishedTimeout && clearTimeout(this._finishedTimeout);
        }

    }

    GUI.LxValueSelectionView = LxValueSelectionView;
    return GUI;
}(window.GUI || {});
