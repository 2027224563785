'use strict';
/**
 *
 * ctor(details)    details contains:
 *      {
 *          options:    reference to a view to be shown here
 *          title:      optional - shown above the list of options
 *          origin:     the element that this context menu was opened from. used for HD to reposition the ctxt menu content
 *      }
 */

window.GUI = function (GUI) {
    class LxGenericContextMenu extends GUI.LxContextMenuBase {
        constructor(details) {
            super(...arguments);
            Debug.GUI.ContextMenu && console.log(this.name, "constructor");
        }

        getContentView() {
            var cntn = this.options.contentView;
            if (cntn.reload) {
                return cntn.reload().then(() => { return cntn; });
            } else {
                return cntn
            }
        }

        _loadOptions() {
            return Q.resolve()
        }

        showConfirmButton() {
            return true;
        }

    }

    GUI.LxGenericContextMenu = LxGenericContextMenu;
    return GUI;
}(window.GUI || {});
