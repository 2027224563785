'use strict';

window.GUI = function (GUI) {
    class WebContentScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var SANDBOX_PERMISSIONS = 'allow-scripts allow-forms allow-popups allow-pointer-lock allow-same-origin';

            var getTemplate = function getTemplate() {
                return $('<div class="web-content-screen">' + '<div class="web-content-screen__container">' + '<iframe class="container__iframe" sandbox="' + SANDBOX_PERMISSIONS + '" frameborder="0"></iframe>' + '</div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super(WebContentScreen.Template.getTemplate());

            if (!details.title || !details.url) {
                console.error("WebContentScreen needs both title and url!");
            }

            this.title = details.title;
            this.url = details.url;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {
                container: this.element.find(".web-content-screen__container"),
                iframe: this.element.find(".container__iframe")
            };
            this.elements.iframe.attr('src', this.url);
        }

        destroy() {
            this.title = null;
            this.url = null;
            this.elements = null;
            super.destroy();
        }

        updateView(details) {
            super.updateView(...arguments);

            if (typeof details.title === "string") {
                this.title = details.title;
            }

            if (typeof details.url === "string") {
                this.url = details.url;
            }

            this.titleBar.setTitleBarSideTexts(this.title);
            this.elements.iframe.attr('src', this.url);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            return encodeURIComponent(this.title);
        }

        titleBarText() {
            return this.title;
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE
            };
        }

    }

    GUI.WebContentScreen = WebContentScreen;
    return GUI;
}(window.GUI || {});
