'use strict';
/**
 *
 * ctor(details)    details contains:
 *      color       the color to tint the bar in.
 *      value       the value = the height of the bar, 0-100
 *      limitValue  the position of a limit bar
 *      iconSrc     the icon to use on the top
 *      iconColor   the color for the icon
 *      additionalIconSrc       an additional icon shown on the right side, next to the iconSrc-icon
 *      additionalIconColor     the color used for the addIconSrc
 *      icon2Src    the second icon, shown below the iconSrc-icon
 *      icon2Color  the color for icon 2
 *      [action]    the action to call when the bar is tapped.
 *      showPattern when true, the bar will have a pattern (diagonal stripes) background
 */

window.GUI = function (GUI) {
    class LxPercentageBar extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getTemplate() {
                return '' + '<div class="lx-percentage-bar">' + '<div class="lx-percentage-bar__container">' + '<div class="container__bar"></div>' + '<div class="container__limit">' + '<div class="limit__line"></div>' + '</div>' + '<div class="container__icons">' + '<div class="icons__icon-cntr1"></div>' + '<div class="icons__icon-cntr2"></div>' + '</div>' + '<div class="container__value">' + '<span class="value__val"></span>' + '<span class="value__percent">%</span>' + '</div>' + '</div>' + '</div>';
            } //endregion Static


        }; //endregion Static

        constructor(details) {
            super($(LxPercentageBar.Template.getTemplate()));
            this.details = details;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.elements = {};
                this.elements.bar = this.getElement().find(".container__bar");
                this.elements.iconCntr = this.getElement().find(".container__icons");
                this.elements.iconCntr1 = this.getElement().find(".icons__icon-cntr1");
                this.elements.iconCntr2 = this.getElement().find(".icons__icon-cntr2");
                this.elements.value = this.getElement().find(".value__val");
                this.elements.limit = this.getElement().find(".container__limit");

                if (this.details.action) {
                    this.button = new GUI.LxButton(this, this.element);
                    this.addToHandledSubviews(this.button);
                    this.button.onButtonTapped = this.details.action;
                }

                this.details.color && this.setColor(this.details.color);
                this.details.iconSrc && this.setIcon(this.details.iconSrc);
                this.details.iconColor && this.setIconColor(this.details.iconColor);
                this.details.additionalIconSrc && this.setAdditionalIcon(this.details.additionalIconSrc);
                this.details.additionalIconColor && this.setAdditionalIconColor(this.details.additionalIconColor);
                this.details.icon2Src && this.setIcon2(this.details.icon2Src);
                this.details.icon2Color && this.setIcon2Color(this.details.icon2Color);
                this.details.value && this.setValue(this.details.value);
                this.details.showPattern && this.setValue(this.details.showPattern);
            }.bind(this));
        }

        setValue(value) {
            var valueString = lxFormat("%.0f", value);
            this.elements.bar.css("height", valueString + "%");
            this.elements.value.text(valueString);
        }

        setColor(color) {
            this.elements.bar.css("background-color", color ? color : "");
        }

        setIcon(iconSrc) {
            //this.elements.iconCntr.empty();
            if (this.elements.icon) {
                this.elements.icon.remove();
                this.elements.icon = null;
            }

            if (iconSrc) {
                this.elements.iconCntr1.prepend(ImageBox.getResourceImageWithClasses(iconSrc, "icon-cntr1__icon"));
                this.elements.icon = this.elements.iconCntr1.find(".icon-cntr1__icon");
            }
        }

        setIconColor(color) {
            this.elements.icon.css("fill", color);
        }

        setAdditionalIcon(iconSrc) {
            if (this.elements.additionalIcon) {
                this.elements.additionalIcon.remove();
                this.elements.additionalIcon = null;
            }

            if (iconSrc) {
                this.elements.iconCntr1.append(ImageBox.getResourceImageWithClasses(iconSrc, "icon-cntr1__additional-icon"));
                this.elements.additionalIcon = this.elements.iconCntr1.find(".icon-cntr1__additional-icon");
            }
        }

        setAdditionalIconColor(color) {
            if (this.elements.additionalIcon) {
                this.elements.additionalIcon.css("fill", color);
            }
        }

        setIcon2(iconSrc) {
            if (this.elements.icon2) {
                this.elements.icon2.remove();
                this.elements.icon2 = null;
            }

            if (iconSrc) {
                this.elements.iconCntr2.append(ImageBox.getResourceImageWithClasses(iconSrc, "icon-cntr2__icon2"));
                this.elements.icon2 = this.elements.iconCntr2.find(".icon-cntr2__icon2");
            }
        }

        setIcon2Color(color) {
            if (this.elements.icon2) {
                this.elements.icon2.css("fill", color);
            }
        }

        setLimit(value) {
            var percentString = lxFormat("%.0f%", value);
            if(value < 0) {
                this.elements.limit.hide();
            } else {
                this.elements.limit.show();
                this.elements.limit.css("height", percentString);
            }
        }

        setShowPattern(active) {
            this.elements.bar.toggleClass("container__bar--pattern", !!active);
        }

    }

    GUI.LxPercentageBar = LxPercentageBar;
    return GUI;
}(window.GUI || {});
