'use strict';
/**
 *
 * ctor(details)    details contains:
 *      {
 *          options:    see below for details
 *          title:      optional - shown above the list of options
 *          origin:     the element that this context menu was opened from. used for HD to reposition the ctxt menu content
 *      }
 *
 * options an array of individual options to pick from.
 *      {
 *          title           text shown for this option
 *          [action]        optional action to call when the option was selected.
 *          [titleColor]    optional titleColor
 *          [loadFn]        optional the loadFn will be called and the result shown.
 *          [disabled]      optional, if true, the cell is deactivated, but shown and not clickable
 *      }
 *
 * onOptionSelected(idx)        called when an option is selected that has no action
 */

window.GUI = function (GUI) {
    class LxTimePickerContextMenu extends GUI.LxContextMenuBase {
        //region Static
        static TYPE = {
            DATE: "date",
            DATE_TIME: "dateTime",
            DURATION: "duration",
            TIME: "time"
        }; //endregion Static

        constructor(details) {
            super(...arguments);
            Debug.GUI.ContextMenu && console.log(this.name, "constructor");
        }

        getContentView() {
            var pickerView;

            switch (this.options.type) {
                case this.constructor.TYPE.DATE:
                    pickerView = new GUI.LxPickerViewDate(this.options);
                    break;

                case this.constructor.TYPE.DATE_TIME:
                    pickerView = new GUI.LxPickerViewDateTime(this.options);
                    break;

                case this.constructor.TYPE.DURATION:
                    pickerView = new GUI.LxPickerViewDuration(this.options);
                    break;

                case this.constructor.TYPE.TIME:
                    pickerView = new GUI.LxPickerViewTime(this.options);
                    break;
            }

            return pickerView;
        }

        showConfirmButton() {
            return true;
        }

    }

    GUI.LxTimePickerContextMenu = LxTimePickerContextMenu;
    return GUI;
}(window.GUI || {});
