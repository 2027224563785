'use strict';

/**
 * Can be used to display color gradients & alike in certain shapes (BAR)
 *
 * setColors(colors)    will redraw the view with the colors provided (each color is a App-Color-Object)
 */

class ColorView extends GUI.View {
    constructor(type, colors) {
        if (typeof type === "undefined") {
            throw new Error("ColorView must be initialized with a type!");
        }

        var element = $('<div class="color-view" />');
        super(element);
        this._overlay = $('<div class="color-view__overlay" />');element.append(this._overlay);
        this._cssGradientPrefix = getCssGradientPrefix();
        this.colors = !!colors ? cloneObject(colors) : null;
        this.setType(type);
    }

    /**
     * updates the type and redraws the canvas if needed
     * @param type
     */
    setType(type) {
        var promise;

        if (this.type !== type) {
            this.type = type;
            GUI.animationHandler.schedule(function () {
                this.element.toggleClass("color-view--bar", this.type === GUI.ColorViewType.BAR);
                this.element.toggleClass("color-view--ball", this.type === GUI.ColorViewType.BALL);
                this.element.toggleClass("color-view--warm-cold", this.type === GUI.ColorViewType.WARM_COLD);
                this.element.toggleClass("color-view--hue", this.type === GUI.ColorViewType.HUE);
            this.element.toggleClass("color-view--daylight", this.type === GUI.ColorViewType.DAYLIGHT);
}.bind(this));            promise = this.draw();
        } else {
            promise = Q.resolve();
        }

        return promise;
    }

    /**
     * Will update the colors shown in the preview bar. The background will be a gradient
     * @param colors        Array of colors to show in the preview.
     */
    setColors(colors) {
        if (!colors || colors.length === 0) {
            console.error("Cannot work with no colors, at least one has to be provided!");
            colors = [LxColorUtils.createHSVObjectFromRGBString(Color.STATE_INACTIVE)];
        }

        this.colors = cloneObject(colors);
        return this.draw();
    }

    setOverlayText(text) {
        this._overlay.text(text || "");
    }

    draw() {
        var hlsColors = this._getHslColors();

        switch (this.type) {
            case GUI.ColorViewType.BAR:
                this._drawLinearGradientWith(hlsColors, true);

                break;

            case GUI.ColorViewType.BALL:
                this._drawLinearGradientWith(hlsColors, true);

                break;

            case GUI.ColorViewType.HUE:
                this._drawHueColorsWheel();

                break;

            case GUI.ColorViewType.WARM_COLD:case GUI.ColorViewType.DAYLIGHT:
                this._drawLinearGradientWith(['#00a9ff', 'white', 'white', '#ffd500']);

                break;

            default:
                break;
        }
    }

    _drawRadialGradientWith(colors) {
        if (!colors) {
            return;
        }

        if (colors.length === 1) {
            colors.push(colors[0]);
        }

        var gradient = this._cssGradientPrefix + "radial-gradient(" + colors.join(", ") + ")";
        console.log(this.viewId, "_drawRadialGradientWith: " + gradient);
        GUI.animationHandler.schedule(function () {
            this.element[0].style.backgroundImage = gradient;
        }.bind(this));
    }

    _drawLinearGradientWith(colors, leftToRight) {
        if (!colors) {
            return;
        }

        if (colors.length === 1) {
            colors.push(colors[0]);
        }

        var gradient = this._cssGradientPrefix + "linear-gradient(" + (leftToRight ? "180deg, " : "") + colors.join(", ") + ")";
        console.log(this.viewId, "_drawLinearGradientWith: " + gradient);
        GUI.animationHandler.schedule(function () {
            this.element[0].style.backgroundImage = gradient;
        }.bind(this));
    }

    _drawHueColorsWheel() {
        var colors = [];
        var startRad, endRad, color;

        for (var angle = 360; angle > 0; angle--) {
            startRad = angle * Math.PI / 180;
            endRad = (angle + 2) * Math.PI / 180;
            color = 'hsl(' + (angle + 90) % 360 + ', 100%, 50%)';
            colors.push(color);
        }

        this._drawConicGradientWith(colors);
    }

    _drawConicGradientWith(colors) {
        var gradient = "conic-gradient(" + colors.join(", ") + ")";
        console.log(this.viewId, "_drawConicGradientWith: " + gradient);
        GUI.animationHandler.schedule(function () {
            this.element[0].style.backgroundImage = gradient;
        }.bind(this));
    }

    _getHslColors() {
        if (!this.colors) {
            console.warn("ColorView setColors called with no color!");
            return;
        }

        var hslTexts = [];
        this.colors.forEach(function (color) {
            hslTexts.push(this._getHslaColorForDrawing(color));
        }.bind(this));
        return hslTexts;
    }

    /**
     * Returns a hsla string of the color adopted so it looks nice on the UI.
     * @param color     the color object.
     * @return {string} the hsla string for drawing
     * @private
     */
    _getHslaColorForDrawing(color) {
        return LxColorUtils.getHSLATextForDrawing(color);
    }

}

GUI.ColorView = ColorView;
GUI.ColorViewType = {
    BAR: 0,
    BALL: 1,
    HUE: 2,
    WARM_COLD: 3,
    DAYLIGHT: 4
};
/**
 * http://www.arndt-bruenner.de/mathe/scripts/dreiecksberechnungrw.htm
 * @param hsv
 * @param radius
 * @returns {{x, y}}
 */

var hsvToCoordinates = function (hsv, radius) {
    var h = hsv.h,
        s = hsv.s,
        c = radius * s / 100,
        degrees = getDegrees(h),
        beta = degrees.beta,
        a = c * Math.cos(toRadians(beta)),
        b = Math.sqrt(Math.pow(c, 2) - Math.pow(a, 2));
    return getCoordinates(radius, h, a, b);
};
/**
 * @param kelvin
 * @param radius
 * @returns {{x, y}}
 */


var tempToCoordinates = function (kelvin, radius) {
    var size = radius * 2;
    kelvin = Math.max(Math.min(6500, kelvin), 2700); // adopt kelvin so the indicator doesn't get drawn outside the color circle

    return {
        x: radius,
        y: size - Math.round((kelvin - 2700) / (6500 - 2700) * size)
    };
};
/**
 * returns degrees of the triangle with a give "h" value (from hue)
 * @param h
 * @returns {{alpha: *, beta: *, gamma: number}}
 */


var getDegrees = function (h) {
    var alpha,
        beta,
        gamma = 90;

    if (h <= 90) {
        beta = h;
        alpha = 180 - gamma - beta;
    } else if (h > 90 && h <= 180) {
        alpha = 180 - h;
        beta = 180 - gamma - alpha;
    } else if (h > 180 && h <= 270) {
        beta = h - 180;
        alpha = 180 - gamma - beta;
    } else if (h > 270 && h <= 360) {
        alpha = 360 - h;
        beta = 180 - gamma - alpha;
    } else {
        throw new Error("can't calculate degrees from h " + h);
    }

    return {
        alpha: alpha,
        beta: beta,
        gamma: gamma
    };
};

var getCoordinates = function (radius, h, a, b) {
    if (h >= 0 && h <= 90) {
        return {
            x: radius + a,
            y: radius - b
        };
    } else if (h <= 180) {
        return {
            x: radius - b,
            y: radius - a
        };
    } else if (h <= 270) {
        return {
            x: radius - a,
            y: radius + b
        };
    } else if (h <= 360) {
        return {
            x: radius + b,
            y: radius + a
        };
    }
};

function toDegrees(rad) {
    return rad * (180 / Math.PI);
}

function toRadians(deg) {
    return deg * (Math.PI / 180);
}

var coordinatesToHsv = function (x, y, radius) {
    if (x === radius) {
        if (y <= radius) {
            return {
                h: 90,
                s: (radius - y) / radius * 100,
                v: 100
            };
        } else {
            return {
                h: 270,
                s: (y - radius) / radius * 100,
                v: 100
            };
        }
    } else if (y === radius) {
        if (x <= radius) {
            return {
                h: 180,
                s: (radius - x) / radius * 100,
                v: 100
            };
        } else {
            return {
                h: 0,
                s: (y - radius) / radius * 100,
                v: 100
            };
        }
    }

    var lengths = getTriangleLengths(x, y, radius);
    var q = Math.pow(lengths.a, 2) / lengths.c,
        p = lengths.c - q,
        h = Math.sqrt(p * q),
        alpha = toDegrees(Math.atan(h / p)),
        beta = 90 - alpha,
        hue,
        saturation = lengths.c;

    if (x <= radius && y <= radius) {
        // left top -> alpha
        hue = 180 - alpha;
    } else if (x <= radius && y > radius) {
        // left bottom -> alpha
        hue = 270 - alpha;
    } else if (x > radius && y <= radius) {
        // right top -> beta
        hue = beta;
    } else if (x > radius && y > radius) {
        // right bottom -> beta
        hue = 270 + beta;
    } else {
        throw new Error("can't calculate hsv from values");
    }

    return {
        h: hue,
        s: Math.min(saturation / radius * 100, 100),
        v: 100
    };
};

var getTriangleLengths = function getTriangleLengths(x, y, radius) {
    var c, a, b;

    if (x <= radius && y <= radius) {
        // left top -> alpha
        a = radius - y;
        b = radius - x;
    } else if (x <= radius && y > radius) {
        // left bottom -> alpha
        a = radius - x;
        b = y - radius;
    } else if (x > radius && y <= radius) {
        // right top -> beta
        a = x - radius;
        b = radius - y;
    } else if (x > radius && y > radius) {
        // right bottom -> beta
        a = y - radius;
        b = x - radius;
    } else {
        throw new Error("can't calculate lengths from values");
    }

    c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
    return {
        c: c,
        a: a,
        b: b
    };
};
