'use strict';

window.GUI = function (GUI) {
    class LxTabBar extends GUI.View {
        //region Static
        static Template = function () {
            var createTabBar = function createTabBar(position) {
                return $('<div class="lx-tab-bar">' + '<div class="lx-tab-bar__spacer"></div>' + '   <div class="lx-tab-bar__tab-container ' + position + '"></div>' + '</div>');
            };

            var createTab = function createTab(icon, text) {
                return $('<div class="tab-container__tab">' + '   <div class="tab__icon-placeholder clickable">' + '       ' + ImageBox.getResourceImageWithClasses(icon, "icon-placeholder__icon") + '       <div class="icon-placeholder__badge">' + '           <div class="badge__nr" />' + '       </div>' + '   </div>' + (typeof text === "string" ? '<div class="tab__text">' + text + '</div>' : '') + '</div>');
            };

            var getEntryPointButton = function getEntryPointButton(icon, text) {
                return $('<div class="tab-bar__entry-point-button">' + '   <div class="entry-point-button__icon-container">' + '       ' + ImageBox.getResourceImageWithClasses(Icon.Menu.ENTRY_POINT, "icon-container__icon") + '   </div>' + '</div>');
            };

            return {
                createTabBar: createTabBar,
                createTab: createTab,
                getEntryPointButton: getEntryPointButton
            };
        }(); //endregion Static

        constructor(tabs, layout) {
            let position = null;

            switch (layout) {
                case TabBar.DIRECTION.VERTICAL:
                    position = "tab-container--vertical";
                    break;

                case TabBar.DIRECTION.HORIZONTAL:
                    position = "tab-container--horizontal";
                    break;

                default:
                    console.warn("LxTabBar: layout '" + layout + "' not supported, using horizontal");
                    position = "tab-container--horizontal";
                    break;
            }

            super(LxTabBar.Template.createTabBar(position));
            this._position = position;
            this.tabs = tabs;
            this.tabButtons = [];
            this.elements = {};
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            var i,
                tab,
                tabElement,
                tabButton,
                tabButtonsContainer = this.element.find('.lx-tab-bar__tab-container');

            for (i = 0; i < this.tabs.length; i++) {
                tab = this.tabs[i];
                tabElement = LxTabBar.Template.createTab(tab.icon, tab.text);
                tabButton = new GUI.LxButton(this, tabElement);
                tabButton.onButtonTapped = this.selectTab.bind(this, i);
                this.addToHandledSubviews(tabButton);
                this.tabButtons.push(tabButton);
                tabButtonsContainer.append(tabElement);
            } // on HD the entry point is to be selectable from the tab bar.


            if (HD_APP) {
                this.element.append(LxTabBar.Template.getEntryPointButton());
                this.elements.entryPointButton = this.element.find(".tab-bar__entry-point-button");
                this.entryPointButton = new GUI.LxButton(this, this.elements.entryPointButton[0]);
                this.addToHandledSubviews(this.entryPointButton);
                this.elements.entryPointIcon = this.elements.entryPointButton.find(".entry-point-button__icon");
            }

            this.element.find(".icon-placeholder__badge").hide();
        }

        viewWillAppear() {
            super.viewWillAppear();
            $(document.body).addClass("tab-bar-appeared");

            if (this.entryPointButton) {
                this.entryPointButton.onButtonTapped = this._showEntryPointLocation.bind(this);
                this.entryPointButton.onButtonHit = this._entryPointButtonHit.bind(this);
                this.entryPointButton.onButtonReleased = this._entryPointButtonReleased.bind(this);
                this.entryPointButton.onButtonDoubleTapped = this._showEntryPointMenu.bind(this);

                this._updateEntryPointButton();
            }
        }

        viewWillDisappear() {
            super.viewWillDisappear();
            $(document.body).removeClass("tab-bar-appeared");

            if (this.entryPointButton) {
                this.entryPointButton.onButtonTapped = null;
                this.entryPointButton.onButtonHit = null;
                this.entryPointButton.onButtonReleased = null;
                this.entryPointButton.onButtonDoubleTapped = null;
            }
        }

        /**
         * selects tab at index
         * @param tabIdx
         * @param triggerEvent
         * @param forceAnimation    if true, the animation has to be an animation.
         */
        selectTab(tabIdx, triggerEvent, forceAnimation) {
            if (typeof tabIdx === "number" && tabIdx !== -1) {
                var selectedActiveTab = this.activeTab === this.tabButtons[tabIdx];

                if (!!triggerEvent || !selectedActiveTab || forceAnimation) {
                    // only start the animation if the event comes from a touch
                    // or we selected another tab
                    this.activeTab && this.activeTab.setActive(false);
                }

                this.activeTab = this.tabButtons[tabIdx];
                setTimeout(function () {
                    this.activeTab && this.activeTab.setActive(true);
                }.bind(this));
                triggerEvent && this.onTabSelected(tabIdx, this.activeTab);
            }

            this.currentTabIdx = tabIdx;
        }

        /**
         * sets the badge number of tab
         * @param {number} tabIdx
         * @param {number} badgeNr
         */
        setBadgeNrForTab(tabIdx, badgeNr) {
            var tab = this._getTabBadgeAtIndex(tabIdx);

            tab.toggle(badgeNr > 0);
            tab.find(".badge__nr").text(badgeNr);
        }

        /**
         * callback onTabSelected - override to add action
         * @param {number} tabIdx
         * @param tab
         */
        onTabSelected(tabIdx, tab) {
            console.info("override onTabSelected");
        }

        /**
         * shows or hides a tab
         * @param {number} tabIdx
         * @param {boolean} visible
         */
        setVisibilityOfTab(tabIdx, visible) {
            this._getTabAtIndex(tabIdx).toggle(visible);
        }

        /**
         * @returns {Number} current tab idx
         */
        getCurrentTabIdx() {
            return this.currentTabIdx;
        }

        /**
         * returns tab at index
         * @param {number} tabIdx
         * @returns {jQuery}
         * @private
         */
        _getTabAtIndex(tabIdx) {
            return $(this.element.find(".lx-tab-bar__tab")[tabIdx]);
        }

        /**
         * returns tab badge at index
         * @param {number} tabIdx
         * @returns {jQuery}
         * @private
         */
        _getTabBadgeAtIndex(tabIdx) {
            return $(this.element.find(".lx-tab-bar__tab")[tabIdx]).find(".icon-placeholder__badge");
        }

        // ---------- Entry Point Handling -----------------

        /**
         * Will navigate to the entry point and also ensures that the tab bar will animate.
         * @private
         */
        _showEntryPointLocation() {
            var tabIdx = -1,
                loc = EntryPointHelper.getLocation(); // check if an entry point is defined yet

            if (!loc) {
                this._showEntryPointMenu();

                return;
            } else {
                loc = loc.split("/")[0];
            } // detect tab index to select.


            switch (loc) {
                case UrlStartLocation.FAVORITES:
                    tabIdx = 0;
                    break;

                case UrlStartLocation.CENTRAL:
                    tabIdx = 1;
                    break;

                case UrlStartLocation.ROOM:
                case UrlStartLocation.LIKE_PRESENCE_DETECTION:
                    // always a room.
                    tabIdx = 2;
                    break;

                case UrlStartLocation.CATEGORY:
                    tabIdx = 3;
                    break;

                default:
                    break;
            }

            if (tabIdx >= 0) {
                this.selectTab(tabIdx, false, true);
            } // actually show the entry point.


            NavigationComp.showEntryPointLocation();
        }

        /**
         * Starts a timeout to detect long presses, that should open the menu
         * @private
         */
        _entryPointButtonHit() {
            this._entryPointMenuTimeout = setTimeout(this._showEntryPointMenu.bind(this), 1000);
        }

        /**
         * Button release detection - should navigate to the entry point menu.
         * @private
         */
        _entryPointButtonReleased() {
            if (this._entryPointMenuTimeout) {
                // still running as the button is released, stop timeout & show entry point
                this._clearMenuTimeout();

                this._showEntryPointLocation();
            }
        }

        /**
         * Opens up the entry point menu and also stops any running timeouts for the menu.
         * @private
         */
        _showEntryPointMenu() {
            this._clearMenuTimeout();

            NavigationComp.showState(ScreenState.EntryPointLocationSelection);
        }

        _updateEntryPointButton() {
            var needsButton = EntryPointHelper.needsEntryPointButton();
            var spacer = this.element.find(".lx-tab-bar__spacer");

            if (needsButton) {
                spacer.show();
            } else {
                spacer.hide();
            } // show/hide the button


            this.toggleSubview(this.entryPointButton, needsButton);
        }

        /**
         * Will reset the show entry point menu timeouts.
         * @private
         */
        _clearMenuTimeout() {
            this._entryPointMenuTimeout && clearTimeout(this._entryPointMenuTimeout);
            this._entryPointMenuTimeout = null;
        }

    }

    GUI.LxTabBar = LxTabBar;
    return GUI;
}(window.GUI || {});
