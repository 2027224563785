'use strict';
/*
    type                        default: text
    leftIconSrc
    rightIconSrc
    title
    value
    placeholder
    disabled
    autoFocus                   (boolean)
    selectText                   (boolean)
    validationRegex             used to ensure no unsupported characters are used.
    validationFn                called when the validity cannot be checked via regex.
    filterRegex                 used to remove unsupported characters from the text provided
    validationErrorText         The error text if validation fails defaults to _('invalid-input')
    [numberStep]                optional step if type is number
    [numberMin]                 optional step if type is number
    [numberMax]                 optional step if type is number
    [maxlength]                 optional Max length of characters
    showGreen                   determines if validated text is shown green.
    [style]                     optional. if missing -> default style.
    [resetButton]               optional boolean. default: false. if true an X will be shown to reset the search content.
                                !! resetButton: true is not to be used with a validation at the moment (UI not adopted)
    [isDark]                    optional boolean. default: false. If true the input field will assume it's on a dark bg (as in a
                                titlebar) no matter if dark or light mode is active.
    [biometricSecretType]       Defines the type of the secret the password represents (BiometricHelper.SecretType), only works
                                if the defined type is GUI.LxInputEnum.Type.PASSWORD
                                The Input will display the Biometric Glyph on the right side of the input, when the user clicks on
                                it the the secret is requested from the Keychain and will appear in the input field
    [biometricSecretTargetMs]   Provides the miniserver the secret is for. This usually can be empty, but is required if no activeMs is available

 // InputCell methods
    focus()    // focuses the input

 // InputCell delegate
    textChanged(value, valid, valueDidChange)
    submitText(value, valid)
    // TODO add onSubmit function (onkeyup -> enter?)

// when a delegate isn't used, but the methods set later - these can be set by inputView.onTextChanged = this.handleText.bind(this)
    onTextChanged(value, valid)
     onSubmit(value)
 */

window.GUI = function (GUI) {
    GUI.LxInputEnum = {
        Type: {
            SEARCH: "search",
            PASSWORD: "password",
            TEXT: "text",
            TELEPHONE: "tel",
            NUMBER: "number",
            DATE: "date"
        },
        FOCUS_TIMEOUT: 100,
        VALUE: "value",
        DISABLED: "disabled",
        REGEX: "validationRegex",
        VALIDATION_FN: "validationFn",
        // a regex (not a pattern) used to verify the input.
        ERROR_TEXT: "validationErrorText",
        FILTER: "filterRegex",
        MULTI_LINE: "multiline",
        TITLE: "title",
        READONLY: "readonly",
        // was only used by autopilot-rule-screen for description. isn't used atm.
        NAME: "name",
        PLACEHOLDER: "placeholder",
        STEP: "numberStep",
        MIN: "numberMin",
        MAX: "numberMax",
        MAX_CHARS: "maxLength",
        SHOW_GREEN: "showGreen",
        // if a valid input text is to be shown in green.
        STYLE: "style",
        RESET_BUTTON: "resetButton",
        // boolean - if a reset button is to be shown.
        IS_DARK: "isDark",
        // boolean - if true, the field assumes to be presented on a dark background
        TRANSLUCENT: "translucent",
        // boolean - if true, isDark is activated & the input bg color is adopted
        BIOMETRIC_SECRET_TYPE: "biometricSecretType",
        // BiometricHelper.SecretType
        BIOMETRIC_SECRET_TARGET_MS: "biometricSecretTargetMs" // BiometricHelper.SecretType

    }; //TODO-woessto: ensure that both reset & validation work.

    GUI.LxInputStyle = {
        ROUND: "round",
        ROUNDED_SQUARE: "rounded-square"
    };

    class Input extends GUI.View {
        //region Static
        static Template = function () {
            var getInputContent = function getInputContent(content) {
                var leftIcon = content.leftIconSrc ? ImageBox.getResourceImageWithClasses(content.leftIconSrc, "lx-input__left-icon") : '',
                    rightIcon = content.rightIconSrc ? ImageBox.getResourceImageWithClasses(content.rightIconSrc, "lx-input__right-icon") : '',
                    type = content.type ? content.type : GUI.LxInputEnum.Type.TEXT,
                    value = content.hasOwnProperty(GUI.LxInputEnum.VALUE) && content.value !== null && content.value !== undefined ? content.value : '',
                    // htmlEncode() the placeholder to prevent XSS attacks, without escaping one could smuggle attributes or whole tags into the dom! by invalidating the placeholder property
                    placeholder = content.hasOwnProperty(GUI.LxInputEnum.PLACEHOLDER) ? (content[GUI.LxInputEnum.PLACEHOLDER] + "").htmlEncode() : '',
                    name = content.hasOwnProperty(GUI.LxInputEnum.NAME) ? content[GUI.LxInputEnum.NAME] : '',
                    // autoFocus = content.autoFocus ? ' autofocus' : ''; --> using this corrupts the UI on mobile devices.
                    pattern = "",
                    readonly = content.hasOwnProperty(GUI.LxInputEnum.READONLY) ? " readonly" : "",
                    optionalClasses = "",
                    inputBox,
                    resetButton = "",
                    biometricButton = ""; // secure against invalid arguments (e.g. passing a string pattern instead of an regex object)

                if (content.hasOwnProperty(GUI.LxInputEnum.REGEX)) {
                    try {
                        pattern = ' pattern="' + content[GUI.LxInputEnum.REGEX].patternify() + '"';
                    } catch (ex) {
                        console.error("RegEx could not be converted to a pattern! " + content[GUI.LxInputEnum.REGEX]);
                    }
                } // the value maybe needs to be filtered to ensure no unsupported characters are used.


                if (content.filterRegex) {
                    value = value.regexFilter(content.filterRegex);
                }

                if (!!content[GUI.LxInputEnum.RESET_BUTTON]) {
                    resetButton = '' + '<div class="input-box__reset-button">' + ImageBox.getResourceImageWithClasses(Icon.CLOSE_ROUND, "reset-button__icon") + '</div>';
                }

                biometricButton = '' + '<div class="input-box__biometric-button">' + ImageBox.getResourceImageWithClasses(BiometricHelper.getBiometricGlyph(), "biometric-button__icon") + '</div>';

                if (content.hasOwnProperty(GUI.LxInputEnum.READONLY)) {
                    optionalClasses += "readonly-input";
                }

                var inputElement;

                if (content[GUI.LxInputEnum.MULTI_LINE]) {
                    value = value.replace(/\/n/g, "\n"); // because the miniserver escapes a \n as /n

                    inputElement = '<textarea class="input-box__input text-area ' + optionalClasses + '" autocapitalize="off" autocorrect="off" name="' + name + '" placeholder="' + placeholder + '" required' + pattern + readonly + '>' + value + '</textarea>';
                } else {
                    inputElement = '<input type="' + type + '" class="input-box__input" autocapitalize="off" autocorrect="off" value="' + value + '" name="' + name + '" placeholder="' + placeholder + '" required' + pattern + readonly + '>';
                } // the inputbox will contain both the input field/text-area and the error-message
                // present an inline error-message!


                inputBox = '' + '<div class="lx-input__input-box">' + inputElement + '<div class="input-box__error">' + (content[GUI.LxInputEnum.ERROR_TEXT] || _('invalid-input')) + '</div>' + resetButton + biometricButton + '</div>';
                var element = $('<div class="lx-input">' + leftIcon + (content.hasOwnProperty(GUI.LxInputEnum.TITLE) ? '<div class="lx-input__title">' + content[GUI.LxInputEnum.TITLE] + '</div>' : '') + inputBox + rightIcon + '</div>');
                var input = element.find("input, textarea");

                if (typeof content[GUI.LxInputEnum.STEP] === "number") {
                    input.attr("step", content[GUI.LxInputEnum.STEP]);
                }

                if (typeof content[GUI.LxInputEnum.MIN] === "number") {
                    input.attr("min", content[GUI.LxInputEnum.MIN]);
                }

                if (typeof content[GUI.LxInputEnum.MAX] === "number") {
                    input.attr("max", content[GUI.LxInputEnum.MAX]);
                }

                if (typeof content[GUI.LxInputEnum.MAX_CHARS] === "number") {
                    input.attr("maxlength", content[GUI.LxInputEnum.MAX_CHARS]);
                }

                if (content[GUI.LxInputEnum.DISABLED]) {
                    input.attr("disabled", true);
                }

                return element;
            };

            return {
                getInputContent: getInputContent
            };
        }(); //endregion Static

        constructor(configuration, delegate) {
            let config = configuration; // Sanitize the configuration

            if (config.type !== GUI.LxInputEnum.Type.NUMBER && typeof config.value !== "undefined" && config.value !== null) {
                config.value = config.value.toString();
            }

            super(Input.Template.getInputContent(config));
            Object.assign(this, RippleContainer.Mixin);
            this.config = config;

            if (this.config.type && this.config.type === GUI.LxInputEnum.Type.SEARCH) {
                this.config.leftIconSrc = Icon.SEARCH;
            }

            this.delegate = delegate;

            if (HD_APP) {
                this.element.addClass("lx-input--hd");
            }

            this.inputField = this.element.find('.input-box__input');
        }

        viewDidLoad() {
            var vdlPrms = super.viewDidLoad(...arguments);

            if (!!this.config.isInAmbientMode) {
                this.element.addClass("lx-input-for-ambient");
            }

            if (this.config.hasOwnProperty(GUI.LxInputEnum.TITLE)) {
                this.element.addClass("lx-input-with-title");
            }

            if (this.config.hasOwnProperty(GUI.LxInputEnum.MULTI_LINE)) {
                this.element.addClass("lx-input--multiline");
            }

            if (this.config.hasOwnProperty(GUI.LxInputEnum.STYLE)) {
                this.element.addClass("lx-input-style--" + this.config[GUI.LxInputEnum.STYLE]);
            }

            if (!!this.config[GUI.LxInputEnum.RESET_BUTTON]) {
                this.element.addClass("lx-input--reset-button");
                var resetElem = this.element.find(".input-box__reset-button");
                this.resetButton = new GUI.LxButton(this, resetElem, Color.BUTTON_GLOW, null, true);
                this.addToHandledSubviews(this.resetButton);
                this.hideSubview(this.resetButton);
            }

            this.updateBiometricSecretInfo(this.config[GUI.LxInputEnum.BIOMETRIC_SECRET_TARGET_MS]);
            this.useValidation = false;

            if (this.config.hasOwnProperty(GUI.LxInputEnum.REGEX)) {
                this.regex = this.config[GUI.LxInputEnum.REGEX];
                this.useValidation = true;
            }

            if (this.config.hasOwnProperty(GUI.LxInputEnum.VALIDATION_FN)) {
                this.validationFn = this.config[GUI.LxInputEnum.VALIDATION_FN];
                this.useValidation = true;
            }

            // most of the time we want to use the showGreen-effect, except for when we explicitly deactivate it.
            this.showGreen = !!this.config[GUI.LxInputEnum.SHOW_GREEN] || !this.config.hasOwnProperty(GUI.LxInputEnum.SHOW_GREEN);

            if (this.config.type) {
                this.element.addClass("lx-input-style--" + this.config.type);
            }

            if (!!this.config[GUI.LxInputEnum.TRANSLUCENT]) {
                this.element.addClass("lx-input-style--translucent");
                this.element.addClass("lx-input--dark-background");
            }

            if (this.config[GUI.LxInputEnum.IS_DARK]) {
                this.element.addClass("lx-input--dark-background");
            }

            return vdlPrms;
        }

        viewWillAppear() {
            var vwaPrms = super.viewWillAppear(...arguments); // Set the correct padding, we can't use CSS, as it will use the parents width as a base
            // https://www.w3.org/TR/CSS2/box.html#padding-properties
            // NOTE: Only do this for password fields to prevent the "invalide value" message from not being shown because of the padding

            if (!this.config.hasOwnProperty(GUI.LxInputEnum.MULTI_LINE) && this.config.type === GUI.LxInputEnum.Type.PASSWORD) {
                var height = this.inputField.height(),
                    fontSize = this.inputField.css("font-size"),
                    paddingString = height / 2 - (parseInt(fontSize) + 2) / 2 + "px";
                this.inputField.css("padding-top", paddingString);
                this.inputField.css("padding-bottom", paddingString);
            } // Register to set/unset the focus once we show the ScreenSaver

            return vwaPrms;
        }

        viewDidAppear() {
            var wdaPrms = super.viewDidAppear(...arguments);
            this.pasteHandler = this._handlePasteEvent.bind(this);
            this.element[0].addEventListener("paste", this.pasteHandler, this.showGreen);

            if (this.useValidation) {
                this._updateValidity(this.inputField[0].checkValidity(), false);
            }

            this.inputField[0].onfocus = function (e) {
                e.preventDefault();
                e.stopPropagation();

                if (this.useValidation) {
                    this._updateValidity(this.inputField[0].checkValidity(), this.showGreen);
                }

                this._focus();
            }.bind(this);

            var delegation = this._handleInputFieldEvent.bind(this);

            this.inputField[0].onfocus = delegation.bind(this);
            this.inputField[0].onkeyup = delegation.bind(this);
            this.inputField[0].onblur = delegation.bind(this);
            this.inputField[0].onchange = delegation.bind(this); // The selectText method automatically also focuses the element, so no need to call the focus function

            if (this.config.selectText) {
                // use autoFocus with timeout to ensure the UI is up and ready, otherwise the position is wrong
                setTimeout(function () {
                    this.selectText();
                }.bind(this), GUI.LxInputEnum.FOCUS_TIMEOUT); //50 is too short for mobile devices
            } else if (this.config.autoFocus) {
                // use autoFocus with timeout to ensure the UI is up and ready, otherwise the position is wrong
                setTimeout(function () {
                    this.inputField.focus();
                }.bind(this), GUI.LxInputEnum.FOCUS_TIMEOUT); //50 is too short for mobile devices
            }

            if (this.config.resetButton) {
                this.resetButton.onButtonTapped = this._resetInputContent.bind(this);
            }

            if (this.biometricButton) {
                this.biometricButton.onButtonTapped = this._requestBiometricSecret.bind(this);
            } // tell the css that this view is visible again.


            this.element.removeClass("lx-input--invisible");
            return wdaPrms;
        }

        viewWillDisappear() {
            this.element[0].removeEventListener("paste", this.pasteHandler);
            this.inputField[0].onfocus = null;
            this.inputField[0].onkeyup = null;
            this.inputField[0].onblur = null;
            this.inputField[0].onchange = null;
            this.inputField.blur(); // fix for iOS 10, otherwise the keyboard will appear after an animation

            clearTimeout(this._checkTimeout); // tell the css that the view is going to be invisible.

            this.element.addClass("lx-input--invisible");

            if (this.config.resetButton) {
                this.resetButton.onButtonTapped = null;
            }

            if (this.biometricButton) {
                this.biometricButton.onButtonTapped = null;
            } // Register to set/unset the focus once we show the ScreenSaver


            return super.viewWillDisappear(...arguments);
        }

        /**
         * Hides and shows the keyboard when the Screensaver is shown/hidden
         * @param active
         */
        toggleScreenSaver(active) {
            var isFocused = this.inputField.is(":focus");

            if (active && isFocused) {
                this.removeFocus();
            } else if (this._cachedFocusState) {
                this.focus();
            }

            this._cachedFocusState = isFocused;
        }

        /**
         * Updates the input fields value and triggers a validation. Returns the validation result.
         * @param newValue
         * @returns {*}     if the new value is valid.
         */
        setValue(newValue) {
            this.inputField.val(newValue);

            if (this.resetButton) {
                this.toggleSubview(this.resetButton, newValue.length > 0);
            }

            if (this.useValidation) {
                return this._validateValue();
            } else {
                return this.inputField[0].checkValidity();
            }
        }

        getValue() {
            return this.inputField.val();
        }

        /**
         * Simulates the enter key on the input element
         */
        submit() {
            // Just simulate an "enter"
            this._handleInputFieldEvent({
                type: "change",
                keyCode: 13
            });
        }

        /**
         * sets the type of the input field
         * @param type
         */
        setType(type) {
            this.inputField.attr("type", type);
        }

        /**
         * returns whether or not the value in the input is valid.
         * @returns {boolean}
         */
        isValid() {
            return this.inputField[0].checkValidity();
        }

        focus() {
            this.inputField.focus();
        }

        /**
         * Will ensure that the input field is no longer focused by calling blur() on it
         */
        removeFocus() {
            this.inputField.blur();
        }

        /**
         * focuses/selects the text of the input
         */
        selectText() {
            // setSelectionRange only works on password, search and text! (https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange)
            if (this.inputField[0].setSelectionRange) {
                var len = this.inputField.val().length; // This only works if the element is focused per specification!

                if (!this.inputField.is(':focus')) {
                    this.focus();
                }

                this.inputField[0].setSelectionRange(0, len);
            }
        }

        updateBiometricSecretInfo(targetMs) {
            var isBiometricSecretAvailable,
                biometricButtonElement = this.element.find(".input-box__biometric-button");

            if (targetMs) {
                this.targetMs = targetMs;
            } else {
                this.targetMs = ActiveMSComponent.getActiveMiniserver();
            }

            isBiometricSecretAvailable = this._isBiometricSecretAvailable();
            this.element.toggleClass("lx-input--biometric-button", isBiometricSecretAvailable);

            if (!this.biometricButton) {
                this.biometricButton = new GUI.LxButton(this, biometricButtonElement, Color.BUTTON_GLOW, null, true);
                this.addToHandledSubviews(this.biometricButton);
            }

            this.toggleSubview(this.biometricButton, isBiometricSecretAvailable);
        }

        _focus() {
            this._moveCursorToEnd();
        }

        _resetInputContent() {
            this.setValue("");

            this._dispatchTextChanged("", this.isValid(), true);

            this._focus();
        }

        _requestBiometricSecret() {
            BiometricHelper.getSecretOfType(this.config[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], this.targetMs, true).then(function (secret) {
                this.setValue(secret);

                this._dispatchTextChanged(secret, this.isValid(), true);

                this._focus();

                this._dispatchSubmit(secret, this.isValid(), true);
            }.bind(this), function (errorCode) {
                var platform = PlatformComponent.getPlatformInfoObj().platform; // As designed only implemented for iOS

                if (platform === PlatformType.IOS && errorCode !== BiometricHelper.ErrorCodes.UserCanceled) {
                    // don't use the removeFocus function here the element might be detached from dom
                    document.activeElement.blur();
                    NavigationComp.showPopup({
                        message: _('biometrics.login-error-text', {
                            biometricType: BiometricHelper.getBiometricTypePhrase()
                        }),
                        buttonOk: true
                    }).then(function () {
                        this.toggleSubview(this.biometricButton, false);
                        PersistenceComponent.setBiometricTokenForSecretType(null, this.config[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], this.targetMs.serialNo, this.targetMs.activeUser);
                        PersistenceComponent.setBiometricSecretWithSecretTypeEnabled(this.config[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], true, this.targetMs.serialNo, this.targetMs.activeUser);
                    }.bind(this));
                }
            }.bind(this));
        }

        /**
         * This moves the cursor to the end of the input element
         */
        _moveCursorToEnd() {
            if (this.inputField[0].setSelectionRange && ( // setSelectionRange only works on password, search and text! (https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange)
                this.config.type === GUI.LxInputEnum.Type.PASSWORD || this.config.type === GUI.LxInputEnum.Type.SEARCH || this.config.type === GUI.LxInputEnum.Type.TEXT)) {
                // Double the length because Opera is inconsistent about whether a carriage return is one character or two
                var len = this.inputField.val().length * 2;
                this.inputField[0].setSelectionRange(len, len);
            } else {
                this.inputField.val(this.inputField.val());
            } // Scroll to the bottom, in case we're in a tall textarea
            // (Necessary for Firefox and Google Chrome)


            this.inputField[0].scrollTop = 999999;
        }

        _updateValidity(valid, showGreen) {
            // only use green and the default color. red is no longer used.
            if (valid && showGreen) {
                this.inputField.css("color", window.Styles.colors.green);
            } else {
                this.inputField.css("color", "");
            }

            if (this.inputField.val() === "") {
                valid = true;
            }

            this.element.toggleClass("lx-input--invalid", !valid);
        }

        /**
         * Deals with the user event when someone pastes sth into these fields
         * @private
         */
        _handlePasteEvent() {
            // Just simulate an "enter"
            this._handleInputFieldEvent({
                type: "change",
                keyCode: 13
            });
        }

        _handleInputFieldEvent(event) {
            var valid, value, didChange;

            if (event.type === "blur" && this.onBlur) {
                this.onBlur();
            } else if (event.type === "focus" && this.onFocus) {
                this.onFocus();
            }

            if (this.useValidation) {
                valid = this._validateValue();
            } else {
                if (this.config.type === GUI.LxInputEnum.Type.NUMBER) {
                    // use the build in checkValidity function for number inputs, it'll automatically check
                    // for min/max properties if provided
                    valid = this.inputField[0].checkValidity();
                } else {
                    // if we don't have validation, check if string isn't empty!
                    valid = this.inputField.val() !== "";
                }
            }

            if (valid && event.type === "blur") {
                // remove the green color on blur, otherwise we have a lot of green fields
                this.inputField.css("color", "");
                return;
            } // Here we check if the event type is equal to "change". This indicates that either the browser has autofilled the form or
            // the user or a Password extension like 1Password copied in the value.


            value = this.inputField.val();
            didChange = event.type === "change" || event.type === "keyup";

            if (event.keyCode === 13 && !this.config[GUI.LxInputEnum.MULTI_LINE]) {
                // enter, but don't dispatch a submit event if it is a multi line input field
                setTimeout(function () {
                    this._dispatchSubmit(value, valid, didChange);
                }.bind(this), 0);
            } else {
                setTimeout(function () {
                    this._dispatchTextChanged(value, valid, didChange);
                }.bind(this), 0);
            }
        }

        _dispatchSubmit(value, valid, didChange) {
            if (this.delegate && this.delegate.submitText) {
                this.delegate.submitText(value, valid, didChange);
            }

            if (this.onSubmit) {
                this.onSubmit(value, valid);
            }
        }

        _dispatchTextChanged(value, valid, didChange) {
            if (this.delegate && this.delegate.submitText) {
                this.delegate.textChanged(value, valid, didChange);
            }

            if (this.onTextChanged && didChange) {
                this.onTextChanged(value, valid);
            }

            if (this.resetButton) {
                this.toggleSubview(this.resetButton, value.length > 0);
            }
        }

        _validateValue() {
            var valid;
            clearTimeout(this._checkTimeout);
            valid = this.inputField[0].checkValidity();
            if (this.regex && valid) {
                valid = valid && this.regex.test(this.inputField[0].value);
            }
            if (this.validationFn && valid) {
                valid = valid && this.validationFn(this.inputField[0].value);
            }

            this._updateValidity(valid, this.showGreen); // if valid, up


            if (!valid) {
                this._checkTimeout = setTimeout(function () {
                    // use a short timeout (otherwise it will go red while typing 12,4 -> ',')
                    valid = this.inputField[0].checkValidity();
                    if (this.regex && valid) {
                        valid = valid && this.regex.test(this.inputField[0].value);
                    }
                    if (this.validationFn && valid) {
                        valid = valid && this.validationFn(this.inputField[0].value);
                    }

                    this._updateValidity(valid, this.showGreen);
                }.bind(this), GUI.LxInputEnum.FOCUS_TIMEOUT);
            }

            return valid;
        }

        /**
         * Returns true if all required conditions are met for Biometric secret to be available
         * Condition: Type must be of password, a biometric secret type must be set in the config,
         *            biometrics must be enrolled, finally a token for the secret must be available and the secret must be enabled
         * @returns {boolean}
         * @private
         */
        _isBiometricSecretAvailable() {
            var userPwToken = null,
                isBiometricSecretEnabled = false;

            if (this.config.type === GUI.LxInputEnum.Type.PASSWORD && this.config.hasOwnProperty(GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE) && BiometricHelper.hasEnrolledBiometrics) {
                userPwToken = PersistenceComponent.getBiometricTokenForSecretType(this.config[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], this.targetMs.serialNo, this.targetMs.activeUser);
                isBiometricSecretEnabled = PersistenceComponent.isBiometricSecretWithTypeEnabled(this.config[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], this.targetMs.serialNo, this.targetMs.activeUser);
            } // Force a boolean type to ensure toggleClass works correctly!


            return !!(userPwToken && isBiometricSecretEnabled);
        }

    }

    GUI.LxInput = Input;
    return GUI;
}(window.GUI || {});
