'use strict';

window.GUI = function (GUI) {
    class LxKnightRiderBar extends GUI.View {
        //region Static
        static Template = function () {
            var getBar = function getBar() {
                return '' + '<div class="knight-rider-bar">' + '<div class="knight-rider-bar__car"></div>' + // 'drives' around
                    '<div class="knight-rider-bar__notch"></div>' + // the line
                    '</div>';
            };

            return {
                getBar: getBar
            };
        }(); //endregion Static

        constructor() {
            super($(LxKnightRiderBar.Template.getBar()));
            this.eventHandlers = [];
        }

        setAnimate(isActive) {
            this.element.toggleClass('animate', isActive);
        }

        setIntegrated(active) {
            this.element.toggleClass('knight-rider-bar--integrated', active !== false);
        }

    }

    GUI.LxKnightRiderBar = LxKnightRiderBar;
    return GUI;
}(window.GUI || {});
