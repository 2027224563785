'use strict';
/**
 * Abstract class to implement a Context Menu, its mandatory to override the methode "getContentView"
 * @param title: The title of the Menu
 * @param options: Object which defines options for the menu
 * @param origin: The element that this context menu was opened from. used for HD to reposition the ctxt menu content
 * onHideContextMenu(menu)      called when the context menu is to be closed.
 * @type {{}}
 */

window.GUI = function (GUI) {
    class ContextMenuBase extends GUI.View {
        //region Static
        static Template = function () {
            var getContent = function getContent(title) {
                var titleElem = '';

                if (title) {
                    titleElem = '<div class="placeholder__title-bar">' + '   <div class="title-bar__left-spacer"></div>' + '   <div class="title-bar__title">' + title + '</div>' + '   <div class="title-bar__right-button">' + '   ' + ImageBox.getResourceImageWithClasses(Icon.TitleBar.TICK, "right-button__icon") + '   </div>' + '</div>';
                }

                return $('<div class="context-menu__scrollfix">' + // prevents scrolling on the underlying scroll views.
                    '<div class="scrollfix__larger-child"></div>' + '</div>' + '<div class="context-menu__placeholder">' + titleElem + '</div>' + '</div>');
            };

            var getCancelButton = function getCancelButton() {
                return $('<div class="placeholder__cancel">' + _("cancel") + '</div>');
            };

            return {
                getContent: getContent,
                getCancelButton: getCancelButton
            };
        }(); //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        ANIMATION_TYPE = {
            LEFT: 0,
            CENTER: 1,
            RIGHT: 2,
            BOTTOM_RIGHT: 3,
            BOTTOM_LEFT: 4
        }; //endregion Private

        constructor(details) {
            super($('<div />'));
            Debug.GUI.ContextMenu && console.log(this.name, "constructor");
            this.element.toggleClass("context-menu-base--hd", this._showAsHDVersion());
            this.title = details.title;
            this.options = details.options;
            this.origin = details.origin;
        }

        viewDidLoad() {
            Debug.GUI.ContextMenu && console.log(this.name, "viewDidLoad"); // load async options before continuing with viewDidLoad

            var promises = [super.viewDidLoad(...arguments) || true, this.getContentView()];
            var content = ContextMenuBase.Template.getContent(this.title),
                showConfButton = this.showConfirmButton();
            return Q.all(promises).then(function (results) {
                Debug.GUI.ContextMenu && console.log(this.name, "  data loaded, viewDidLoad continues");
                this.contentView = results[1];
                return GUI.animationHandler.schedule(function () {
                    this.element.append(content);
                    this.element[0].onclick = (evt) => this.onCloseButtonTapped(null, evt); // close the context menu, when background is clicked, hammerjs not used because of the slider
                    this.element.toggleClass("context-menu-base--confirmable", showConfButton);
                }.bind(this)).then(function () {
                    this.elements = this.elements || {};
                    this.elements.contentPlaceholder = this.element.find(".context-menu__placeholder");
                    this.elements.titleArea = this.element.find(".placeholder__title-bar");
                    this.elements.titleButton = this.elements.titleArea.find(".title-bar__right-button")
                    this.appendSubview(this.contentView, this.elements.contentPlaceholder);
                    // will be dismissed if the title area is tapped.

                    if (this.elements.titleArea[0]) {
                        this.titleButton = new GUI.LxButton(this, this.elements.titleButton[0]);
                        this.titleButton.onButtonTapped = this.onCloseButtonTapped.bind(this);
                        this.addToHandledSubviews(this.titleButton);
                    }

                    this._boundRemoveCtxMenu = this._handleCancel.bind(this);

                    this.registerForResize();
                }.bind(this));
            }.bind(this));
        }

        onCloseButtonTapped(button,event) {
            // Handle a long press/click on the tableViewCells
            // The context menu should not get hidden, so we only hide it if the events srcElement
            // is not part of the tableView element
            if (!this.contentView.getElement().find(event.srcElement)[0]) {
                this._boundRemoveCtxMenu.apply(button, arguments);
            }
        }

        viewWillAppear() {
            Debug.GUI.ContextMenu && console.log(this.name, "viewWillAppear");
            return super.viewWillAppear(...arguments).then(function () {
                return this.beforeAnimateIn().then(this._animateIn.bind(this));
            }.bind(this));
        }

        showConfirmButton() {
            return false;
        }

        onResize() {
            var prms = null;

            if (this.origin) {
                prms = this._repositionContent();
            }

            return prms || Q.resolve();
        }

        _showAsHDVersion() {
            return !!(HD_APP || this.isInAmbientMode());
        }

        beforeAnimateIn() {
            var prms = null;

            if (this._showAsHDVersion()) {
                if (this.origin) {
                    prms = this._repositionContent();
                } else {
                    prms = this._setAnimationType(this.ANIMATION_TYPE.CENTER);
                }
            }

            return prms || Q.resolve();
        }

        viewDidAppear() {
            var promise = super.viewDidAppear(...arguments);
            NavigationComp.registerForBackNavigation(this._boundRemoveCtxMenu);
            return promise.then(function (res) {
                if (Debug._CYPRESS_TESTS_ACTIVE) {
                    // a context menu is not a screen, but it is an overlay so we treat it as one.
                    triggerEvent(document, "screenDidAppear", {
                        url: this.getURL()
                    });
                }

                return res;
            }.bind(this));
        }

        viewWillDisappear() {
            NavigationComp.unregisterFromBackNavigation(this._boundRemoveCtxMenu);
            return super.viewWillDisappear(...arguments);
        }

        /**
         * Returns an LxView or a promise which resolves an LxView
         * @note This method must me overwritten by a subclass
         */
        getContentView() {
            throw "Override this method!";
        }

        // Private methods
        _handleCancel() {
            this._animateOut().then(function () {
                if (this.onHideContextMenu) {
                    this.onHideContextMenu(this);
                } else {
                    console.error("onHideContextMenu must be set to hide it properly!");
                }
            }.bind(this));
        }

        /**
         * Called when on HD and the hammer event that did cause the contextMenu to appear are known. In this case
         * the content shown in the context menu should be presented next to that location.
         * @private
         */
        _repositionContent() {
            var contextRect = this.elements.contentPlaceholder[0].getBoundingClientRect(),
                extracted = this.origin.getBoundingClientRect(),
                origXPos = extracted.left + extracted.width / 2,
                origYPos = extracted.top + extracted.height / 2,
                marginBottom = "auto",
                marginRight = window.innerWidth - origXPos,
                atPromise;

            if (origXPos - contextRect.width < 0) {
                marginRight -= contextRect.width;
                atPromise = this._setAnimationType(this.ANIMATION_TYPE.RIGHT);
            } else {
                atPromise = this._setAnimationType(this.ANIMATION_TYPE.LEFT);
            }

            if (marginRight < 0) {
                // prevents the context menu from being only partially visible.
                marginRight = 0;
            }

            if (contextRect.height + origYPos > window.innerHeight) {
                // prevents ctxt-menu from moving outside screen
                origYPos += window.innerHeight - (contextRect.height + origYPos);
            }

            if (!this._showAsHDVersion()) {
                marginBottom = "0";
                origYPos = "auto";
            }

            return Q.all([atPromise, GUI.animationHandler.schedule(function () {
                this.elements.contentPlaceholder.css("margin-bottom", marginBottom);
                this.elements.contentPlaceholder.css("margin-left", "auto");
                this.elements.contentPlaceholder.css("margin-right", marginRight);
                this.elements.contentPlaceholder.css("margin-top", origYPos);
            }.bind(this))]);
        }

        _animateIn() {
            var elmAnimateDef = Q.defer(),
                contentPlaceholderDef = Q.defer();

            this._animate(this.element, {
                opacity: [1, 0]
            }, function () {
                elmAnimateDef.resolve();
            });

            if (this._showAsHDVersion()) {
                this._animate(this.elements.contentPlaceholder, {
                    scale: [1, 0]
                }, function () {
                    contentPlaceholderDef.resolve();
                });
            } else {
                var options = {
                    translateY: ["0%", "100%"]
                };

                this._animate(this.elements.contentPlaceholder, options, function () {
                    contentPlaceholderDef.resolve();
                });
            }

            return Q.all([elmAnimateDef.promise, contentPlaceholderDef.promise]);
        }

        _animateOut() {
            var def = Q.defer();

            this._animate(this.element, {
                opacity: [0, 1]
            });

            if (this._showAsHDVersion()) {
                this._animate(this.elements.contentPlaceholder, {
                    scale: [0, 1]
                }, function () {
                    def.resolve();
                });
            } else {
                this._animate(this.elements.contentPlaceholder, {
                    translateY: ["100%", "0%"]
                }, function () {
                    def.resolve();
                });
            }

            return def.promise;
        }

        _animate(element, options, complete) {
            options.translateZ = 0; // Force HA by animating a 3D property

            element.velocity(options, {
                duration: PersistenceComponent.getAnimationState() ? 200 : 0,
                easing: "ease-out-expo",
                complete: complete
            });
        }

        _setAnimationType(animationType) {
            var classesToAdd = [],
                classesToRemove = [],
                rmClassList,
                addClassList,
                elem = this.element;

            switch (animationType) {
                case this.ANIMATION_TYPE.LEFT:
                    classesToRemove.push("context-menu-base--to-right");
                    classesToAdd.push("context-menu-base--to-left");
                    classesToRemove.push("context-menu-base--centered");
                    classesToRemove.push("context-menu-base--bottom-right");
                    classesToRemove.push("context-menu-base--bottom-left");
                    break;

                case this.ANIMATION_TYPE.RIGHT:
                    classesToAdd.push("context-menu-base--to-right");
                    classesToRemove.push("context-menu-base--to-left");
                    classesToRemove.push("context-menu-base--centered");
                    classesToRemove.push("context-menu-base--bottom-right");
                    classesToRemove.push("context-menu-base--bottom-left");
                    break;

                case this.ANIMATION_TYPE.BOTTOM_RIGHT:
                    classesToRemove.push("context-menu-base--to-right");
                    classesToRemove.push("context-menu-base--to-left");
                    classesToRemove.push("context-menu-base--centered");
                    classesToAdd.push("context-menu-base--bottom-right");
                    classesToRemove.push("context-menu-base--bottom-left");
                    break;

                case this.ANIMATION_TYPE.BOTTOM_LEFT:
                    classesToRemove.push("context-menu-base--to-right");
                    classesToRemove.push("context-menu-base--to-left");
                    classesToRemove.push("context-menu-base--centered");
                    classesToRemove.push("context-menu-base--bottom-right");
                    classesToAdd.push("context-menu-base--bottom-left");
                    break;

                default:
                    classesToRemove.push("context-menu-base--to-right");
                    classesToRemove.push("context-menu-base--to-left");
                    classesToAdd.push("context-menu-base--centered");
                    classesToRemove.push("context-menu-base--bottom-right");
                    classesToRemove.push("context-menu-base--bottom-left");
            }

            rmClassList = classesToRemove.join(" ");
            addClassList = classesToAdd.join(" ");
            return GUI.animationHandler.schedule(function () {
                elem.removeClass(rmClassList);
                elem.addClass(addClassList);
            });
        }

    }

    GUI.LxContextMenuBase = ContextMenuBase;
    return GUI;
}(window.GUI || {});
